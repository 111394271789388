function showHelp() {
  return `>where
>who
>what
>contact`;
}

function showWhere() {
  return "France";
}

function showWho() {
  return `eccoved aka orland
htnbHub aka bpa`;
}

function showWhat() {
  return "codename 'H T N B'";
}

function showContact() {
  return "mailto:baptiste.hanrion@asvestis.com";
}

function getOut() {
  return "exit";
}

export default {
  help: showHelp(),
  where: showWhere(),
  what: showWhat(),
  who: showWho(),
  contact: showContact(),
  exit: getOut()
};
