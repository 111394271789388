<template>
  <div class="pt-5 pb-5">
    <p class="text-center text-h3">Accessing the<br />CAR WIDE WEB</p>

    <v-container class="product-section">
      <v-row>
        <v-col cols="12" md="6" style="overflow:hidden">
          <div class="htnb-div"></div>
        </v-col>
        <v-col cols="12" md="6">
          <p class="text-center text-h2">HTNB</p>
          <p class="text-h4">An intelligent, sensitive and talkative device.</p>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              v-for="feature in htnbFeatures"
              :key="feature.name"
            >
              <v-icon dark left large>{{ feature.icon }}</v-icon
              >{{ feature.name }}
            </v-col>
            <v-col align-self="end">
              <v-btn class="full-width" color="primary" @click.stop="gotoHTNB"
                ><v-icon left dark>mdi-arrow-right</v-icon> See it!</v-btn
              >
            </v-col>
          </v-row>
          <!-- <v-btn class="full-width" color="primary" @click.stop="gotoStore"
            ><v-icon left dark>mdi-store</v-icon> Pre-order!</v-btn
          > -->
        </v-col>
      </v-row>
    </v-container>

    <!-- <p class="text-center text-h3">Interact with the<br/>CAR WIDE WEB</p>
    
    <v-container class="product-section">
      <v-row>
        <v-col cols="12" md="6">
          <p class="text-center text-h2">Agar</p>
          <p class="text-h4">
            Interact with the Car Wide Web using the Agar application.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-img src="res/agar_both.png" alt="Agar application on android" />
        </v-col>
      </v-row>
    </v-container>

    <p class="text-center text-h3">Simulate the<br/>CAR WIDE WEB</p>

    <video
      class="video-memory"
      autoplay
      loop
      muted
      poster="https://assets.codepen.io/6093409/river.jpg"
    >
      <source
        src="https://assets.codepen.io/6093409/river.mp4"
        type="video/mp4"
      />
    </video> -->
  </div>
</template>

<script>
export default {
  name: "Product",
  data: () => ({
    htnbFeatures: [
      {
        icon: "mdi-antenna",
        name: "Multi-connectivity",
      },
      {
        icon: "mdi-router",
        name: "Optimised routing logic",
      },
      {
        icon: "mdi-leak",
        name: "Embedded sensors",
      },
      {
        icon: "mdi-alert-circle",
        name: "Collision avoidance",
      },
      {
        icon: "mdi-expansion-card",
        name: "Customisable",
      },
      {
        icon: "mdi-download",
        name: "Virtualised applications",
      },
      {
        icon: "mdi-vpn",
        name: "Secure handshake",
      },
      {
        icon: "mdi-power-socket",
        name: "OBD-II ready",
      },
    ],
  }),
  methods: {
    gotoHTNB() {
      this.$router.push("/htnb");
    },
    gotoStore() {
      this.$router.push("/store");
    },
  },
};
</script>

<style scoped>
.product-section {
  padding-top: 50px;
  padding-bottom: 100px;
}

.htnb-div {
  width: auto;
  height: 400px;
  border-radius: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-image: url("/res/htnb_revd_black.jpg");
  animation: changeHTNB 10s infinite;
}

@keyframes changeHTNB {
  0%,
  100% {
    background-image: url("/res/htnb_revd_black.jpg");
  }
  33% {
    background-image: url("/res/htnb_revd_blue.jpg");
  }
  66% {
    background-image: url("/res/htnb_revd_pink.jpg");
  }
}

.video-memory {
  width: 100%;
  height: auto;
}

.full-width {
  width: 100%;
}
</style>
