<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-center text-h2">Contact</p>
      </v-col>
    </v-row>
    <div class="grid-container">
      <div v-for="(item, i) in cipher" :key="i" class="griditem text-center">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import crypto from "crypto";

export default {
  data: () => ({
    cipher: [],
    text: "ASVESTIS is HERE",
    mechanism: "aes-256-gcm",
  }),

  mounted() {
    this.cipherPolling = setInterval(this.generateCipherText, 1000);
  },

  beforeDestroy() {
    clearInterval(this.cipherPolling);
  },

  methods: {
    generateCipherText() {
      this.engine = crypto.createCipheriv(
        this.mechanism,
        crypto.randomBytes(32),
        crypto.randomBytes(16)
      );
      let res = Buffer.concat([
        this.engine.update(this.text, "utf8"),
        this.engine.final(),
      ]);

      let out = [];
      res.forEach((x) => {
        let hexx = x.toString(16);
        hexx = hexx.length < 2 ? "0" + hexx : hexx;
        out.push(hexx.toUpperCase());
      });
      this.cipher = out;
    },
  },
};
</script>

<style>
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  padding: 10px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}
.grid-item:hover {
  background: #e1e1e1;
  border-top: 1px solid #d0d0d0;
}
</style>
