import Vue from "vue";
import VueRouter from "vue-router";
import Hello from "../views/Hello.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/hello",
    name: "Hello",
    component: Hello,
  },
  {
    path: "/",
    name: "Splash",
    component: () => import("../views/Splash.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/htnb",
    name: "HTNB",
    component: () => import("../views/HTNB.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

export default router;
