<template>
  <div>
    <div class="full-viewport">
      <v-img
        height="100%"
        width="100%"
        src="res/htnb_revd.jpg"
        alt="HTNB"
      />
      <div class="title">
        <transition name="fade" mode="out-in">
          <v-img
            v-if="showTitle"
            class="mb-4"
            src="/res/brand_opt.svg"
            alt="brand"
          />
        </transition>
      </div>
      <!-- <div class="subtitle">
        <p class="text-toward">Towards a confident mobility.</p>
      </div> -->
      <transition name="fade" mode="out-in">
        <v-icon v-if="showSubtitle" class="scroll-down" color="primary" dark x-large>mdi-chevron-down</v-icon>
      </transition>
    </div>
    <What id="what" />
    <Product id="product" />
    <Awards id="prices" />
    <!-- <Countdown id="clock" /> -->
    <Hack id="hack" />
    <Terminal id="term" />
    <!-- <Powered id="power" /> -->
  </div>
</template>

<script>
import Awards from "@/components/main/Awards";
// import Countdown from "@/components/main/Countdown";
import Terminal from "@/components/main/Terminal";
// import Powered from "@/components/main/Powered";
import Product from "@/components/main/Product";
import Hack from "@/components/main/Hack";
import What from "@/components/main/What";

export default {
  components: {
    Awards,
    // Countdown,
    Terminal,
    // Powered,
    Product,
    Hack,
    What,
  },

  data: () => ({
    showTitle: false,
    timeoutTitle: 500,
    showSubtitle: false,
    timeoutSubtitle: 1000,
    showHelper: false,
    timeoutHelper: 1200,
    showScrollDown: false,
    timeoutScrollDown: 1400,
  }),

  mounted() {
    setTimeout(() => {
      this.showTitle = true;
    }, this.timeoutTitle);
    setTimeout(() => {
      this.showSubtitle = true;
    }, this.timeoutSubtitle);
    setTimeout(() => {
      this.showHelper = true;
    }, this.timeoutHelper);
    setTimeout(() => {
      this.showScrollDown = true;
    }, this.timeoutScrollDown);

    window.addEventListener("scroll", this.scrolling);
  },

  methods: {
    scrolling(e) {
      e.preventDefault();
    },
  },
};
</script>

<style>
.full-viewport {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.almost-full-viewport {
  width: 100vw;
  height: 90vh;
  position: relative;
}

.hr-divider {
  border: 4px solid #fe9800ff;
}

.text-orange {
  color: #fe9800ff;
}

.text-toward {
  font-size: 4vw !important;
  font-weight: 400;
  line-height: 3.125rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
  color: #fe9800ff;
  white-space: nowrap;
}

.title {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -15%);
  text-align: center;
  width: 80%;
  max-width: 1000px;
}
.subtitle {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -95%);
}

.scroll-down {
  position: absolute !important;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 0px);
  animation: fillup 1s ease 0.5s infinite alternate-reverse;
}

@keyframes fillup {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 24px;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
.scroll-text {
  font-size: 0.75em;
  padding: 5px;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 2s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
