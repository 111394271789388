<template>
  <div class="pt-5 pb-5">
    <v-container>
      <v-container class="pt-5 pb-5">
        <v-row class="text-center" no-gutters>
          <v-col cols="12">
            <p class="connecting">CONNECTING</p>
          </v-col>
          <v-col cols="12">
            <p class="connecting-what">{{ connectingWhat }}</p>
          </v-col>
        </v-row>
      </v-container>

      <div class="pb-5">
        <p class="text-h3" style="text-align: center; text-justify: inter-word">
          TOWARDS A CLEANER AND A SAFER ROAD TRANSPORTATION NETWORK.
        </p>
      </div>

      <hr class="primary" />

      <p class="car-wide-web">CAR WIDE WEB</p>

      <hr class="primary" />
    </v-container>
  </div>
</template>

<script>
// import CarWideWeb from './CarWideWeb.vue';

export default {
  data: () => ({
    showCardInterval: null,
    showCardIntervalValue: 1000,
    connectingWhat: "CARS",
    connectingWhatChoice: ["CARS", "PEOPLE", "INFRASTRUCTURE"],
    connectingWhatIndex: 0,
  }),

  components: {
    // CarWideWeb
  },

  mounted() {
    this.showCardTimeout = setInterval(() => {
      this.connectingWhat = this.connectingWhatChoice[this.connectingWhatIndex];
      this.connectingWhatIndex =
        (this.connectingWhatIndex + 1) % this.connectingWhatChoice.length;
    }, this.showCardIntervalValue);
  },

  beforeDestroy() {
    if (this.showCardInterval) clearInterval(this.showCardTimeout);
  },
};
</script>

<style scoped>
.connecting {
  font-size: 10vw;
  color: white;
}
.connecting-what {
  font-size: 10vw;
  color: #a0a0a0;
}

.car-wide-web {
  font-size: 12vw;
  color: #fe9800;
  text-align: center;
}

.before-card {
  margin-top: 0;
}
.towards-card {
  width: 80%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}
.midfullscreen {
  min-height: 50vh;
}
.connects {
  align-items: center;
}
.connects-parent {
  width: 100%;
  height: 2px;
  margin-left: 1rem;
  margin-right: 1rem;
}
.connects-line {
  width: 100%;
  height: 100%;
  background-color: #fe9800;
  animation: line-progress 2s ease 0.5s infinite alternate-reverse;
}

@keyframes line-progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>
