<template>
  <transition name="fade" mode="out-in">
    <div class="border fill-width">
      <div ref="terminal"></div>
    </div>
  </transition>
</template>

<script>
import "xterm/css/xterm.css";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import COMMANDS from "@/utils/commands.js";

export default {
  data: () => ({
    terminal: null,
    fitAddon: null,
    curr_line: "",
    entries: [],
    curr_char: 0,
    user: ""
  }),

  beforeDestroy() {
    window.removeEventListener("resize", this.fit);
  },

  mounted() {
    this.user = this.makeUser();
    this.terminal = new Terminal({ cursorBlink: "block" });
    this.fitAddon = new FitAddon();
    this.terminal.loadAddon(this.fitAddon);
    this.terminal.open(this.$refs.terminal);
    this.fitAddon.fit();

    this.makeHeader();

    this.terminal.onKey(this.getCommand);
    this.terminal.prompt = () => {
      this.terminal.write(`\r\n\x33[01:33m${this.user}@asvestis.com\x33[0m$ `);
    };

    window.addEventListener("resize", this.fit);
  },

  methods: {
    makeHeader() {
      var s = "";
      if (window.innerWidth > 966) {
        s = `
             d8888                                     888    d8b\r\n
            d88888                                     888    Y8P\r\n
           d88P888                                     888\r\n
          d88P 888 .d8888b  888  888  .d88b.  .d8888b  888888 888 .d8888b\r\n
         d88P  888 88K      888  888 d8P  Y8b 88K      888    888 88K\r\n
        d88P   888 "Y8888b. Y88  88P 88888888 "Y8888b. 888    888 "Y8888b.\r\n
       d8888888888      X88  Y8bd8P  Y8b.          X88 Y88b.  888      X88\r\n
      d88P     888  88888P'   Y88P    "Y8888   88888P'  "Y888 888  88888P'\r\n`;
        s += "\r\n\r\n";
        this.terminal.write(s);
      }

      s = "Welcome to asvestis.com!";
      s += "\r\n";
      s += "\r\n";
      s += "Please type help for a list of valid commands";
      this.curr_char = 0;
      this.typeBuffer(s);
    },
    getCommand(e) {
      // console.log(e);
      switch (e.key) {
        case "\r":
          this.terminal.writeln("");
          this.startcommand();
          break;
        case "\u0003": // Ctrl+C
          this.entries = [];
          this.prompt();
          break;
        case "\u007f": // Backspace (DEL)
          // Do not delete the prompt
          if (this.terminal._core.buffer.x > 21 && this.entries.length > 0) {
            this.entries = this.entries.slice(0, this.entries.length - 1);
            this.terminal.write("\b \b");
          }
          break;
        case "\u001b[A":
        case "\u001b[B":
        case "\u001b[C":
        case "\u001b[D":
          break;
        default:
          // Print all other characters for demo
          this.terminal.write(e.key);
          this.entries.push(e.key);
      }
    },
    typeBuffer(b) {
      this.terminal.write(b[this.curr_char]);
      this.curr_char++;
      if (this.curr_char < b.length) {
        setTimeout(() => this.typeBuffer(b), Math.random() * 20);
      } else if (this.curr_char == b.length) {
        this.prompt();
      }
    },
    prompt() {
      this.terminal.write(`\r\n\x1B[1;3;33m${this.user}@asvestis.com\x1B[0m$ `);
    },
    startcommand() {
      const cmd = this.entries.join("");
      // console.log(cmd, COMMANDS);

      if (cmd in COMMANDS) {
        const seq = COMMANDS[cmd].split("\n");
        if (seq.includes("exit")) {
          this.$router.go(-2);
          return;
        }

        for (var i in seq) {
          this.terminal.writeln(seq[i]);
        }
      } else {
        this.terminal.writeln("No valid command found!");
      }

      this.entries = [];

      this.prompt();
    },
    makeUser() {
      var uSer = "";
      for (let i = 0; i < 6; i++) {
        uSer += Math.floor(Math.random() * 16).toString(16);
      }
      return uSer;
    },
    fit() {
      this.fitAddon.fit();
    }
  }
};
</script>

<style scoped>
.border {
  max-width: 100%;
  border: 1px solid white;
  margin: 32px;
}
.fill-width {
  max-width: 100%;
  position: relative;
}
.center-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>
