<template>
  <v-container>
    <p class="text-center text-h2">Awards</p>
    <v-row justify="center" align="center">
      <v-col cols="12" md="6" v-for="price in prices" :key="price.name">
        <v-img class="mb-6" :src="price.image"></v-img>
        <h1 class="text-center">{{ price.name }}</h1>
        <h2 class="text-center"><i>{{ price.description }}</i></h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Prices",
  data: () => ({
    prices: [
      {
        name: 'Prix SOFLAB 2021',
        description: `"Equipement de l'opérateur"`,
        image: '/res/Logo-Sofins-2019-Paysage-inverted.png'
      },
      {
        name: 'Prix GPSM 2022',
        description: `"Prix de l'innovation"`,
        image: '/res/GPSM.png'
      }
    ]
  })
};
</script>

<style>
.black-to-primary {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background: linear-gradient(#070707, #121212);
}
</style>
